@layer components {
  .TextField {
    @apply flex flex-col pb-input-pb;

    input[type="number"] {
      -moz-appearance: none;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      @apply m-0 appearance-none;
    }

    &Input {
      @apply form-input w-full rounded border px-3 py-2;
      @apply text-body;
      @apply border-input-primary bg-input-primary text-input-primary;
      @apply hover:border-input-primary--hover;
      @apply focus:border-input-primary--active focus:shadow-transparent focus:ring-transparent;
      @apply active:border-input-primary--active;
      @apply disabled:border-transparent disabled:bg-input-disabled disabled:text-input-disabled;

      &--error {
        @apply border-input-danger;
      }

      &--with-icon {
        @apply pl-6;
      }

      &--secure {
        -webkit-text-security: disc;
      }
    }

    &Input::placeholder {
      @apply text-body font-normal text-input-placeholder;
    }

    &InputIcon {
      @apply absolute top-[50%] -translate-y-[50%] pl-3 text-body font-normal text-input-placeholder;
    }

    &Label {
      @apply text-input-label font-normal text-primary;

      &--required {
        @apply text-input-danger;
      }
    }

    &ErrorLabel {
      @apply invisible flex flex-row items-center gap-1 text-input-error font-normal text-input-danger;

      &--visible {
        @apply visible;
      }
    }

    &HelperText {
      @apply invisible pt-input-helper-pt text-body font-normal text-secondary;

      &--visible {
        @apply visible;
      }

      &--top {
        @apply pt-0;
      }
    }

    &LabelTooltipContainer {
      @apply flex items-center gap-2 pb-input-label-pb;
    }
  }
}
