@layer components {
  .Checkbox {
    @apply pb-2;

    &--in-group {
      @apply pb-0;
    }

    &Input {
      @apply h-5 w-5 rounded;
      @apply hover:cursor-pointer hover:border-gray-500;
      @apply focus:border-gray-600 focus:shadow-transparent focus:ring-transparent;
      @apply active:border-gray-600;
      @apply disabled:cursor-not-allowed disabled:border-transparent disabled:bg-input-disabled;
      @apply hover:disabled:bg-input-disabled;
      @apply checked:bg-green-1 checked:hover:bg-green-1 checked:focus:bg-green-1 checked:active:bg-green-1;
    }

    &Label {
      @apply flex flex-row items-center gap-4;
      @apply hover:cursor-pointer;

      &Text {
        @apply text-body font-normal;
        @apply text-primary;

        &.--disabled {
          @apply text-input-disabled;
        }
      }
    }

    &ErrorLabel {
      @apply invisible flex flex-row items-center gap-1 text-body font-normal text-input-danger;

      &--visible {
        @apply visible;
      }
    }
  }
}
