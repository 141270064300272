.RadioGroup {
  &-root {
    @apply flex flex-col;
  }

  &ErrorLabel {
    @apply invisible flex flex-row items-center gap-1 text-body font-normal text-input-danger;

    &--visible {
      @apply visible;
    }
  }
}
